<template>
  <b-row>
    <b-col cols="4">
      <b-card title="Müşteri Kartı">
        <b-list-group>
          <list-item
            title="Müşteri Tipi"
            :value="customer.customer_type"
          />
          <list-item
            title="Adı Soyadı"
            :value="customer.name"
          />
          <list-item
            title="Firma Adı"
            :value="customer.company_name"
          />
          <list-item
            title="Cinsiyet"
            :value="customer.gender"
          />
          <list-item
            title="Telefon"
            :value="customer.phone"
          />
          <list-item
            title="Telefon 2"
            :value="customer.phone2"
          />
          <list-item
            title="E-Posta"
            :value="customer.email"
          />
          <list-item
            title="Müşteri Notu"
            :value="customer.notes"
          />
          <list-item
            title="TC Kimlik No"
            :value="customer.tckn"
          />
          <list-item
            title="Vergi No"
            :value="customer.tax_number"
          />
          <list-item
            title="Vergi Dairesi"
            :value="customer.tax_office"
          />
          <list-item
            title="Adres"
            :value="addressLine"
          />
          <list-item
            title="Sektör"
            :value="customer.sector"
          />
          <list-item
            title="Meslek"
            :value="customer.job"
          />
          <list-item
            title="Temas Tipi"
            :value="customer.meet"
          />
          <list-item
            title="KVKK"
            :value="kvkk"
          />
        </b-list-group>
        <logo-alert-card />
        <div class="mt-2 text-center">
          <b-button
            variant="primary"
            :to="'/customers/edit/' + customer.id"
          >
            Bilgileri Güncelle
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="8">
      <b-card title="Müşteri Geçmişi">
        <customer-history />
      </b-card>
      <related-person-view />
      <b-card
        title="Hızlı İşlemler"
      >
        <div>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-button
                variant="primary"
                size="sm"
                class="mb-2"
                @click="$router.push('/interviews/add/' + customer.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Görüşme Notu
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="ml-1 mb-2"
                @click="$router.push('/crm/add/' + customer.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Başvuru
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="ml-1 mb-2"
                @click="$router.push('/service/add/' + customer.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Servis Randevusu
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="ml-1 mb-2"
                @click="$router.push('/cars/add/' + customer.id)"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-50"
                />
                Araç Kartı
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <marketing-sms-card :id-com-customer="customer.id" />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BListGroup,
  BButton,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'
import CustomerHistory from '@/views/Customers/view/History.vue'
import LogoAlertCard from '@/views/Customers/components/logoAlertCard.vue'
import RelatedPersonView from '@/views/Customers/view/RelatedPersonView.vue'
import MarketingSmsCard from '@/views/Marketing/Marketing_sms/Index.vue'

export default {
  name: 'CustomersView',
  components: {
    MarketingSmsCard,
    RelatedPersonView,
    LogoAlertCard,
    BRow,
    BCol,
    BCard,
    BListGroup,
    BButton,
    ListItem,
    CustomerHistory,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    addressLine() {
      const addon = []
      if (this.customer.address) {
        addon.push(this.customer.address)
      }
      if (this.customer.city) {
        addon.push(this.customer.city)
      }
      if (this.customer.district) {
        addon.push(this.customer.district)
      }
      if (this.customer.region) {
        addon.push(this.customer.region)
      }
      if (this.customer.neighborhood) {
        addon.push(this.customer.neighborhood)
      }
      return addon.join('/')
    },
    kvkk() {
      if (this.customer.kvkk) {
        return `${this.customer.kvkk_type} - ${this.moment(this.customer.kvkkdate).format('DD.MM.YYYY')}`
      }
      return 'Yok'
    },
  },
  created() {
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      if (this.$route.params.id) {
        this.$store.dispatch('customers/customerView', this.$route.params.id)
          .then(response => {
            if (response.id) {
              this.$store.dispatch('customers/history', response.id)
            }
          })
      }
    },
  },
}
</script>
